const app = require('choo')()
const h = require('hyperscript')

const getTitle = require('./getTitle')
const routes = require('../generated/routes.json')

const templates = {
  default: (page, state, emit) => {
    if (page.content == null) {
      return ''
    }
    if (page.content.type === 'html') {
      const div = h('.content')
      div.innerHTML = page.content.content
      return div
    }
    return ''
  },
}

for (const [href, pageInfo] of Object.entries(routes)) {
  app.route(href, render(pageInfo))
}

app.route('/*', state => {
  window.location.href = state.href
})

function render({ title, template }) {
  const renderPage = templates[template]
  return (state, emit) => {
    const page = state.pages[state.href || '/']
    if (page == null) return h('main')
    return h('main', [header(state), renderPage(page, state, emit)])
  }
}

function header(state) {
  const index = state.pages['/']
  return h('.header', [
    h('.logo', h('a', { href: '/' }, h('img', { src: index.logo.href }))),
    h('h1', index.title.content),
    h(
      'ul.nav',
      index._subpages.map((href, i) =>
        h('li', h('a', { href }, routes[href].title)),
      ),
    ),
  ])
}

app.use((state, emitter) => {
  const render = () => emitter.emit('render')

  state.pages = state.pages || {}
  state.status = state.status || 'success'

  emitter.on(state.events.NAVIGATE, () => {
    state.status = 'loading'
    lazyLoad(state.href)
      .then(page => {
        state.status = 'success'
        const index = state.pages['/']
        emitter.emit(
          state.events.DOMTITLECHANGE,
          getTitle(
            state.href,
            index.title.content,
            page.title && page.title.content,
          ),
        )
        render()
      })
      .catch(error => {
        state.status = 'error'
        console.error(error)
        render()
      })
  })

  emitter.on(state.events.DOMCONTENTLOADED, () => {
    lazyLoad('').then(index => {
      // Preload all menu items for instant page load.
      index._subpages.map(lazyLoad)
      render()
    })
    lazyLoad(state.href).then(render)
  })

  function lazyLoad(href) {
    const key = href || '/'
    if (state.pages[key] == null) {
      return import(href + '/content.js').then(module => {
        const page = module.default
        state.pages[key] = page
        return page
      })
    }
    return Promise.resolve(state.pages[key])
  }
})

// Android pretends that its system fonts are Times/Arial, but they have completely different measurements.
// The solution is to put the Nimbus fonts first in the font stack, but only load the corresponding
// @font-face rules if we're on Android. There is no good way to check what font is actually rendered,
// so I think that this is the least intrusive way to get the fonts I want.
// Linux users are also likely to have a dodgy font situation, so this should help them out too.
try {
  if (/linux/i.test(navigator.platform)) {
    const androidFontStyles = h('link', {
      rel: 'stylesheet',
      href: '/android-fonts.css',
    })
    document.head.appendChild(androidFontStyles)
  }
} catch (_) {}

module.exports = app
